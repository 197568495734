<!-- 供应商供货表 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 供应商 -->
      <FormItem class="marginLeft25">
        <span>供应商：</span>
        <Select placeholder="请输入" v-model="queryFrom.supplier_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in supplierSelect" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 经手人 -->
      <!-- <FormItem class="marginLeft60">
        <span>经手人：</span>
        <Select placeholder="请选择" v-model="queryFrom.dept_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.dept_id" :key="index">{{ item.dept_name }}</Option>
        </Select>
      </FormItem> -->
      <!-- 进货仓库 -->
      <FormItem class="marginLeft60">
        <span>进货仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in storeSelect" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 采购时间 -->
      <FormItem class="marginLeft60">
        <span>采购时间：</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" @on-change="changeTime($event, 1)" clearable></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" placeholder="请选择" class="iviewIptWidth250" v-model="queryFrom.end_time" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
      </FormItem>
      <!-- 产品编码  -->
      <FormItem>
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号 -->
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth307 marginRight40" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginLeft60">
        <span class="pageBtn finger btnSure" @click="searchSupplierList">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
      </FormItem>
      <!-- 导出 -->
      <!-- <FormItem class="po-create-number">
        <span class="pageBtn finger btnSure marginLeft20">
          <Spin v-if="Loagin" style="display: inline-block; color: white;">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spin>
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
        </span>
      </FormItem> -->
    </Form>
    <div class="clearfix tabDiv" ref="tabDiv">
      <Spin fix v-if="Loading"></Spin>
      <Table :productList="listColumns" @on-sort-change="sortHospital" :productData="listData" border show-summary :summary-method="handleSummary" class="table" :pages="pages" @change-page="changePage" totalText="条记录" :total="total" :height="tableHeight"></Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'supplierSupplyListConsign',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      productList: [],
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
          key: 'index',
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '供货品种',
          key: 'supplied_varieties',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '供货总量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '供货金额',
          key: 'purchase_amount',
          sortable: 'custom',
          render: (h, param) => {
            let money = param.row.purchase_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
          align: 'center',
          minWidth: 130,
        },
        {
          title: '供货不含税金额',
          key: 'no_tax_purchase_amount',
          render: (h, param) => {
            let money = param.row.no_tax_purchase_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
          align: 'center',
          minWidth: 130,
        },
        {
          title: '采购比重',
          key: 'purchase_proportion',
          align: 'center',
          minWidth: 90,
          render: (h, param) => {
            let money = ((param.row.purchase_amount / this.purchase_amount_tatol) * 100).toFixed(4)
            money = money ? money : 0
            return h('span', money + '%')
          },
        },
        {
          title: '销售总量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '销售金额',
          sortable: 'custom',
          key: 'sale_amount',
          render: (h, param) => {
            let money = param.row.sale_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
          align: 'center',
          minWidth: 130,
        },
        {
          title: '销售不含税金额',
          key: 'no_tax_sale_amount',
          render: (h, param) => {
            let money = param.row.no_tax_sale_amount
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
          align: 'center',
          minWidth: 130,
        },
        {
          title: '利润',
          key: 'profit',
          sortable: 'custom',
          render: (h, param) => {
            let money = param.row.profit
            money = this.formatMoney(money)
            return h('span', '￥' + money)
          },
          align: 'center',
          minWidth: 120,
        },
        {
          title: '利润率',
          key: 'profit_margin',
          // render: (h, param) => {
          //   return h('span', param.row.profit_margin + '%')
          // },
          align: 'center',
          minWidth: 100,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 150,
          maxWidth: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  /* style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }, */
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      queryFrom: {
        supplier_id: '',
        warehouse_id: '',
        product_id: '',
        begin_time: '',
        end_time: '',
        product_model_code: '',
        specification_id_str: [],
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0, // 数据总数
      searchObj: {}, // 搜索对象
      supplierSelect: [], // 供应商下拉
      storeSelect: [], // 仓库下拉
      productSelect: [], // 产品下拉
      options: {}, // 时间设置
      supplied_varieties_tatol: 0, // 品种总数
      purchase_quantity_tatol: 0, // 采购数量总数
      purchase_amount_tatol: 0, // 采购金额总数
      sale_quantity_tatol: 0, // 销售数量总数
      sale_amount_tatol: 0, // 销售金额总数
      profit_total: 0, // 利润总数
      total_no_tax_purchase_amount: 0, // 不含税采购总金额
      total_no_tax_sale_amount: 0, // 不含税销售总金额
      Loading: false,
      tableHeight: '0',
      modelList: [],
    }
  },
  methods: {
    sortHospital(data) {
      if (data.order == 'desc') {
        this.searchObj.sort_type = '1'
        this.searchObj.sort_str = data.key
      } else if (data.order == 'asc') {
        this.searchObj.sort_type = '2'
        this.searchObj.sort_str = data.key
      } else {
        this.searchObj.sort_type = ''
        this.searchObj.sort_str = ''
      }
      this.getTableList(this.pages, this.searchObj)
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.acceptanceCodeSelect, { product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 合计接口
    factorySupplyTotal(obj) {
      this.$http.get(this.$apiConsign.supplierSupplyTotal, obj, false).then(res => {
        this.supplied_varieties_tatol = res.data.supplied_varieties_tatol
        this.purchase_quantity_tatol = res.data.purchase_quantity_tatol
        this.purchase_amount_tatol = res.data.purchase_amount_tatol
        this.sale_quantity_tatol = res.data.sale_quantity_tatol
        this.sale_amount_tatol = res.data.sale_amount_tatol
        this.profit_total = res.data.profit_total
        this.total_no_tax_purchase_amount = res.data.total_no_tax_purchase_amount
        this.total_no_tax_sale_amount = res.data.total_no_tax_sale_amount
      })
    },
    // 导出
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      this.$http.downFile(this.$apiConsign.getExportSupplierSupply, this.searchObj, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商供货表.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        if (index === 12) {
          sums[key] = {
            key,
            value: '',
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          let v = 0
          if (key === 'sale_amount' || key === 'purchase_amount' || key === 'profit' || key === 'no_tax_purchase_amount' || key === 'no_tax_sale_amount') {
            if (key === 'sale_amount') {
              let money = this.formatMoney(this.sale_amount_tatol)
              // let money = this.sale_amount_tatol
              v = '￥' + money
              // v = '￥' + this.sale_amount_tatol
            }
            if (key === 'purchase_amount') {
              let money = this.formatMoney(this.purchase_amount_tatol)
              // let money = this.purchase_amount_tatol
              v = '￥' + money
              // v = '￥' + this.profit_total
            }
            if (key === 'no_tax_purchase_amount') {
              let money = this.formatMoney(this.total_no_tax_purchase_amount)
              // let money = this.total_no_tax_purchase_amount
              v = '￥' + money
            }
            if (key === 'no_tax_sale_amount') {
              let money = this.formatMoney(this.total_no_tax_sale_amount)
              // let money = this.total_no_tax_sale_amount
              v = '￥' + money
            }

            if (key === 'profit') {
              let money = this.formatMoney(this.profit_total)
              // let money = this.profit_total
              v = '￥' + money
              // v = '￥' + this.profit_total
            }
          }
          if (key === 'purchase_quantity' || key === 'sale_quantity' || key === 'supplied_varieties') {
            if (key === 'purchase_quantity') {
              v = this.purchase_quantity_tatol
            }
            if (key === 'sale_quantity') {
              v = this.sale_quantity_tatol
            }
            if (key === 'supplied_varieties') {
              v = this.supplied_varieties_tatol
            }
          }
          sums[key] = {
            key,
            value: v,
          }
        } else {
          let v = 0
          if (key === 'purchase_proportion') {
            v = '100%'
          }
          if (key === 'supplier_name' || key === 'profit_margin') {
            v = ''
          }
          sums[key] = {
            key,
            value: v,
          }
        }
      })

      return sums
    },
    // 详情
    details(row) {
      let supplier_id = row.supplier_id
      this.$router.push({
        path: '/detailsSupplierSupplyListConsign',
        query: { supplier_id },
      })
    },
    // 获取数据列表
    getTableList(pages, searchItem) {
      let obj = {}
      this.Loading = true
      Object.assign(obj, pages, searchItem)
      obj.sort_type = this.searchObj.sort_type
      this.$http.get(this.$apiConsign.supplierSupplyList, obj, false).then(res => {
        if (res.status) {
          this.Loading = false
          this.listData = res.data.result
          this.total = res.data.total
          this.$nextTick(() => {
            this.tableHeight = this.$refs.tabDiv.offsetHeight - 105 + ''
            this.$forceUpdate()
          })
        }
      })
    },
    // 获取下拉列表
    getSelectList() {
      // 获取供应商下拉
      this.$http.get(this.$apiConsign.getSupplierNameList, true).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
      // 获取仓库下拉
      this.$http.get(this.$apiConsign.permissionWareHouseList, { page: 1, rows: 1000 ,is_consign:1}, true).then(res => {
        if (res.status) {
          this.storeSelect = res.data
        }
      })
      // 获取供应商下的产品下拉选择
      this.$http.get(this.$apiConsign.receiptProductList, { supplier_id: null }, false).then(res => {
        this.productList = res.data
      })
    },
    // 搜索供货表
    searchSupplierList() {
      let model_name = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : null
      let product_model_code = this.queryFrom.product_model_code ? this.queryFrom.product_model_code : null
      let supplier_id = this.queryFrom.supplier_id
      let sort_type = this.queryFrom.sort_type
      let sort_str = this.queryFrom.sort_str
      let warehouse_id = this.queryFrom.warehouse_id
      let product_id = this.queryFrom.product_id
      let begin_time = this.queryFrom.begin_time ? this.$moment(this.queryFrom.begin_time).format('YYYY-MM-DD') : ''
      let end_time = this.queryFrom.end_time ? this.$moment(this.queryFrom.end_time).format('YYYY-MM-DD') : ''
      let obj = { supplier_id, sort_type, sort_str, warehouse_id, product_id, begin_time, end_time, model_name, product_model_code }
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, obj)
      this.factorySupplyTotal(obj)
    },
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.begin_time = e
        that.queryFrom.end_time = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.begin_time)
          },
        }
      } else {
        this.queryFrom.end_time = e
      }
    },
    // 数字转金额处理
    formatMoney(totalNum) {
      // if (typeof totalNum === 'number') {
      //   return totalNum + '.00'
      // } else {
      //   return totalNum
      // }
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
  },
  created() {
    // this.getTableList(this.pages, {})
    this.factorySupplyTotal({})
    this.getSelectList()
    this.queryProductCodeSelect()
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
